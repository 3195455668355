import feathers from "@feathersjs/client";
import { URL_AUTHENTICATION, URL_BASE_API_SOCKET } from "../constants";
import io from "socket.io-client";

// 1. Configuración optimizada del socket
const socket = io(URL_BASE_API_SOCKET, {
  transports: ["websocket"], // Fuerza WebSocket primero
  path: "/socket.io", // Asegura que coincida con el backend
  reconnectionAttempts: 3,
  withCredentials: true,
  secure: true, // Obligatorio para HTTPS
  // rejectUnauthorized: false, // SOLO para desarrollo (quitar en producción)
});
// 2. Configuración simplificada de Feathers
const app = feathers();
app.configure(
  feathers.socketio(socket, {
    timeout: 10000, // 10 segundos es suficiente
  })
);
// 3. Autenticación (configuración correcta)
app.configure(
  feathers.authentication({
    path: URL_AUTHENTICATION,
    storage: window.localStorage,
  })
);
export default app;
